<template>
 <div class="web__table">
         <div class="table__infomobile">
        <div class="table__infomobile__block">
          <h3 class="table__infomobile__title"><img src="../assets/021-boat.svg" alt="Illustration" class="table__infomobile__icon">PACK PLAISANCIER</h3>
          <p class="table__infomobile__text">Site vitrine ou blog : les informations essentielles sur une ou plusieurs pages, ou autour d’un blog</p>
        </div>
        <div class="table__infomobile__block">
          <h3 class="table__infomobile__title"><img src="../assets/027-sailboat.svg" alt="Illustration" class="table__infomobile__icon">PACK SKIPPER</h3>
          <p class="table__infomobile__text">Site e-commerce Prestashop : un site e-commerce clé en main pour vendre vos produits en ligne ou en click&collect, ou pour effectuer des réservations en ligne, géré avec Prestashop, accompagné de pages d'informations selon vos besoins</p>
        </div>
        <div class="table__infomobile__block">
          <h3 class="table__infomobile__title"><img src="../assets/007-barque.svg" alt="Illustration" class="table__infomobile__icon">PACK GRAND EXPLORATEUR</h3>
          <p class="table__infomobile__text">Site avancé sur-mesure : un site multipages sur-mesure avec des fonctionnalités avancées à votre demande – tarif sur devis</p>
        </div>
      </div> 
      <table class="table__spec" align="center" width="41%">
          <tr class="table__linedesktop">
            <td class="table__empty">
            </td>
            <td class="table__empty">
              <img src="../assets/021-boat.svg" alt="Illustration" class="table__illus">
              <p class="table__title">PLAISANCIER</p>
            </td>
            <td class="table__empty">
              <img src="../assets/027-sailboat.svg" alt="Illustration" class="table__illus">
              <p class="table__title">SKIPPER</p>
            </td>
            <td class="table__empty">
              <img src="../assets/007-barque.svg" alt="Illustration" class="table__illus">
              <p class="table__title">GRAND EXPLORATEUR</p>
            </td>
          </tr>
          <tr class="table__linedesktop">
            <td>
              <p>Votre embarcation</p>
            </td>
            <td>
              <p>Site vitrine ou blog</p>
            </td>
            <td>
              <p>Site e-commerce Prestashop</p>
            </td>
            <td>
              <p>Site avancé sur mesure</p>
            </td>
          </tr>
          <tr class="table__linedesktop">
            <td>
              <p>Votre besoin</p>
            </td>
            <td>
              <p>Les informations essentielles sur une ou plusieurs pages, ou autour d’un blog </p>
            </td>
            <td>
              <p>Un site e-commerce clé en main pour vendre vos produits en ligne ou en click&collect, ou pour effectuer des réservations en ligne, géré avec Prestashop, accompagné de pages d'informations selon vos besoins.</p>
            </td>
            <td>
              <p>Un site multipages sur-mesure avec des fonctionnalités avancées à votre demande – tarif sur devis</p>
            </td>
          </tr>
          <tr class="table__linemobile">
            <td class="table__empty">
            </td>
            <td class="table__empty">
              <img src="../assets/021-boat.svg" alt="Illustration" class="table__infomobile__icon">
            </td>
            <td class="table__empty">
              <img src="../assets/027-sailboat.svg" alt="Illustration" class="table__infomobile__icon">
            </td>
            <td class="table__empty">
              <img src="../assets/007-barque.svg" alt="Illustration" class="table__infomobile__icon">
            </td>
          </tr>
          <tr>
            <td>
              <p>Création ou récupération de votre nom de domaine et configuration de votre hébergement web</p>
            </td>
            <td>
              <p><i class="fas fa-check"></i></p>
            </td>
            <td>
              <p><i class="fas fa-check"></i></p>
            </td>
            <td>
              <p><i class="fas fa-check"></i></p>
            </td>
          </tr>
          <tr>
            <td>
              <p>Création ou application de votre charte graphique pour le web</p>
            </td>
            <td>
              <p><i class="fas fa-check"></i></p>
            </td>
            <td>
              <p><i class="fas fa-check"></i></p>
            </td>
            <td>
              <p><i class="fas fa-check"></i></p>
            </td>
          </tr>
          <tr>
            <td>
              <p>Création d’une maquette et hiérarchisation du menu avant validation</p>
            </td>
            <td>
              <p><i class="fas fa-check"></i></p>
            </td>
            <td>
              <p><i class="fas fa-check"></i></p>
            </td>
            <td>
              <p><i class="fas fa-check"></i></p>
            </td>
          </tr>
          <tr>
            <td>
              <p>Personnalisation de thème Wordpress/Prestashop ou sur mesure</p>
            </td>
            <td>
              <p><i class="fas fa-check"></i></p>
            </td>
            <td>
              <p><i class="fas fa-check"></i></p>
            </td>
            <td>
              <p><i class="fas fa-check"></i></p>
            </td>
          </tr>
          <tr>
            <td>
              <p>Site responsive pour mobile, tablette, desktop</p>
            </td>
            <td>
              <p><i class="fas fa-check"></i></p>
            </td>
            <td>
              <p><i class="fas fa-check"></i></p>
            </td>
            <td>
              <p><i class="fas fa-check"></i></p>
            </td>
          </tr>
          <tr>
            <td>
              <p>Rédaction du contenu des pages</p>
            </td>
            <td>
              <p><i class="fas fa-check"></i></p>
            </td>
            <td>
              <p><i class="fas fa-check"></i></p>
            </td>
            <td>
              <p><i class="fas fa-check"></i></p>
            </td>
          </tr>
          <tr>
            <td>
              <p>Optimisation SEO et Paramétrage Google Analytics à la demande</p>
            </td>
            <td>
              <p><i class="fas fa-check"></i></p>
            </td>
            <td>
              <p><i class="fas fa-check"></i></p>
            </td>
            <td>
              <p><i class="fas fa-check"></i></p>
            </td>
          </tr>
          <tr>
            <td>
              <p>Mentions légales et politiques de confidentialités réglementaires</p>
            </td>
            <td>
              <p><i class="fas fa-check"></i></p>
            </td>
            <td>
              <p><i class="fas fa-check"></i></p>
            </td>
            <td>
              <p><i class="fas fa-check"></i></p>
            </td>
          </tr>
          <tr>
            <td>
              <p>Double validation client et retouches</p>
            </td>
            <td>
              <p><i class="fas fa-check"></i></p>
            </td>
            <td>
              <p><i class="fas fa-check"></i></p>
            </td>
            <td>
              <p><i class="fas fa-check"></i></p>
            </td>
          </tr>
          <tr>
            <td>
              <p>En sus sur devis : création du contenu des pages (photos, vidéos, infographies)</p>
            </td>
            <td>
              <p><i class="fas fa-check"></i></p>
            </td>
            <td>
              <p><i class="fas fa-check"></i></p>
            </td>
            <td>
              <p><i class="fas fa-check"></i></p>
            </td>
          </tr>
          <tr>
            <td>
              <p>Session de prise en main des CMS (Wordpress ou Prestashop)</p>
            </td>
            <td>
              <p><i class="fas fa-check"></i></p>
            </td>
            <td>
              <p><i class="fas fa-check"></i></p>
            </td>
            <td>
              <p><i class="fas fa-times"></i></p>
            </td>
          </tr>
          <tr>
            <td>
              <p>Back office pour gérer votre blog ou votre boutique</p>
            </td>
            <td>
              <p><i class="fas fa-check"></i></p>
            </td>
            <td>
              <p><i class="fas fa-check"></i></p>
            </td>
            <td>
              <p><i class="fas fa-times"></i></p>
            </td>
          </tr>
          <tr>
            <td>
              <p>Application monopage et transitions dynamiques</p>
            </td>
            <td>
              <p><i class="fas fa-times"></i></p>
            </td>
            <td>
              <p><i class="fas fa-times"></i></p>
            </td>
            <td>
              <p><i class="fas fa-check"></i></p>
            </td>
          </tr>
    </table>
    <table class="table__price">
      <tr>
            <td>
              <p>TARIFS</p>
            </td>
            <td>
              <p>A partir de 350€HT</p>
            </td>
            <td>
              <p>A partir de 600€HT</p>
            </td>
            <td>
              <p>A partir de 1200€HT</p>
            </td>
          </tr>
    </table>
    <p class="smallprint">*Ces tarifs de base sont donnés à titre indicatif pour un site correspondant aux options de base mentionnées ci-dessus. D’autres options sont également disponibles : rédaction des fiches produits, animations avancées, création de thème-enfant Wordpress et Prestashop sur-mesure, fonctionnalités avancées… Le tarif définitif de votre projet sera établi sur devis contractuel après avoir analysé vos besoins et vos envies. N'hésitez pas à me contacter, le devis est gratuit !</p>   
    <router-link to="Contact"><div class="devis">ME DEMANDER UN DEVIS</div></router-link>
 </div>
</template>

<script>
//import $ from 'jquery'

export default {
  name: '',
  props: {
    msg: String
  },
    mounted() {
    }
}
</script>

<style lang="scss">
.web__table {
  background-image: url('../assets/svg-path7.svg');
  background-size: auto;
    background-position-y: center;
    background-repeat: repeat-x;
  @media screen and (max-width: 769px){
            background-size: cover;
            background-position-y: 300px;
            }
}



table {
  width: 81%;
  margin: auto;
  background-origin: border-box;  
  border-spacing: 0px 1px;
}

.table__price {
  margin-top: 20px;
  font-weight: bold;
  font-size: medium;
  @media (max-width: 768px) {
		width: 90%;
    font-size: small;
	}
}

.table__spec {
  font-size: medium;
  @media (max-width: 768px) {
		width: 90%;
    font-size: x-small;
	}
}

.table__linedesktop {
  @media (max-width: 768px) {
		display: none;
	}
}

.table__linemobile {
  @media (min-width: 769px) {
		display: none;
	}
}

.table__infomobile {
  margin: auto;
  width: 90%;
  margin-bottom: 5px;
  display: flex;
  flex-direction: column;
  @media (min-width: 769px) {
		display: none;
	}
  @media (min-width: 650px) {
		flex-direction: row;
    justify-content: space-between;
	}
  &__title {
    font-family: 'Yanone Kaffeesatz';
    display: flex;
    align-items: center;
  }
  &__icon {
    margin-right: 7px;
    height: 30px;
    width: auto;
  }
  &__text {
    font-size: small;
  }
  &__block {
    margin-bottom: 15px;
    @media (min-width: 650px) {
		width: 30%;
	}
  }
}

td {
  background: transparent;
  text-align: center;
  height: 40px;
  width: 25%;
  font-size: inherit;
  padding: 4px 16px;
  border-top: 1px solid #386ed8;
  @media (max-width: 768px) {
		padding: 4px 0px;
	}
}

.table__illus {
  margin: auto;
  margin-bottom: 10px;
  height: 150px;
    width: auto;
}

.table__title {
  font-family: 'Yanone Kaffeesatz';
  font-size: x-large;
}

.table__empty {
  border: none;
}

.fa-check {
  background: #38e01fc7;
  background-clip: text;
  -webkit-text-fill-color: transparent;
  font-size: initial;
}

.fa-times {
  background: #1c09e0cf;
  background-clip: text;
  -webkit-text-fill-color: transparent;
  font-size: initial;
}

.smallprint {
  width: 90%;
    margin: auto;
    margin-top: 30px;
    font-size: x-small;
}

.devis {
    border: 2px solid #386ed8;
    width: fit-content;
    margin: auto;
    margin-top: 15px;
    margin-bottom: 15px;
    padding: 15px 15px 12px;
    box-sizing: border-box;
    border-radius: 40px;
    font-family: 'Yanone Kaffeesatz';
    font-size: xx-large;
    transition: all 0.25s;
     &:hover {
            background-color: #386ed8;
            box-shadow: 0px 2px 5px 0px black;
            color: white!important;
            transform: scale(1.02);
        }
        &:focus {
            background-color: lighten($color: #386ed8, $amount: 30);
            box-shadow: 0px 2px 5px 0px black;
            color: white;
            transform: scale(1.02);
        }
  @media screen and (max-width: 425px){
            width: 90%;
            text-align: center;
            margin-bottom: 20px;
            }
}
</style>